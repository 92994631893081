import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class ProjectTemplate extends React.Component {
  render() {
    const project = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={project.frontmatter.title}
          description={project.frontmatter.description || project.excerpt}
        />
        <header className="project-header">
          <div className="container sm">
            <div className="grid">
              <div className="column-xs-12">
                <h1 className="project-title">{project.frontmatter.title}</h1>
                <p className="lead">{project.frontmatter.role}</p>
              </div>
            </div>
          </div>
        </header>
        <main className="project-content">
          <div className="container sm">
            <div className="grid">
              <div className="column-xs-12">
                <div dangerouslySetInnerHTML={{ __html: project.html }} />
              </div>
            </div>
          </div>
        </main>

        <nav className="project-nav">
          <ul class="nav-links">
            <li>
              <h4>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  ← {next.frontmatter.title}
                </Link>
              )}
              </h4>
            </li>
            <li>
              <h4>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  {previous.frontmatter.title} →
                </Link>
              )}
              </h4>
            </li>
          </ul>
        </nav>
      </Layout>
    )
  }
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        role
        image {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }

      }
    }
  }
`
